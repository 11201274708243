import React, { useContext } from "react"
import { Link } from "gatsby"
import {
  Anchor,
  Button,
  Box,
  Grid,
  TextInput,
  FormField,
  Form,
  Paragraph,
  Text,
  ResponsiveContext,
} from "grommet"

import { MailOption, Alert, StatusGood } from "grommet-icons"
import addToMailchimp from "gatsby-plugin-mailchimp"

const SignUp = sizeOverride => {
  const size = useContext(ResponsiveContext)

  const [mailchimpMessage, setMailchimpMessage] = React.useState("")

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(e.value.signUpEmail, {
      "group[72183][1]": "1"
    })
      .then()
      .then(data => {
        setMailchimpMessage(data)
      })
  }

  const SignUpForm = (
    <Form onSubmit={e => handleSubmit(e)}>
      <Grid
        fill
        columns={
          sizeOverride.size === "small" || size === "small"
            ? "auto"
            : ["medium", "small"]
        }
        gap="small"
        align="end"
      >
        <Box pad={{ bottom: "small" }}>
          <FormField htmlFor="email" margin="none" name="email">
            <TextInput
              id="email"
              a11yTitle="email address"
              margin="none"
              type="email"
              name="signUpEmail"
              placeholder="Email address"
              icon={<MailOption />}
            />
          </FormField>
        </Box>
        <Box pad={{ bottom: "small" }}>
          <Button
            primary
            value="submit"
            color="brand"
            size="large"
            type="submit"
            label="sign up"
          />
        </Box>
      </Grid>
    </Form>
  )

  const CompleteState = (
    <Box background="light-1" direction="row" gap="medium">
      <Box
        width="xsmall"
        align="center"
        justify="center"
        background={
          mailchimpMessage.result === "success" ? "brand" : "status-warning"
        }
      >
        {mailchimpMessage.result === "success" ? (
          <StatusGood color="white" size="large" />
        ) : (
          <Alert color="black" size="large" />
        )}
      </Box>
      <Box pad="medium">
        <Paragraph
          weight="bold"
          size="large"
          dangerouslySetInnerHTML={{ __html: mailchimpMessage.msg }}
        />
        {mailchimpMessage.result !== "success" ? (
          <Text>
            Or you can{" "}
            <Anchor
              color="blue"
              href="#"
              onClick={e => setMailchimpMessage("")}
            >
              try another email address
            </Anchor>
          </Text>
        ) : (
          <Text>
            You should have a confirmation email in your inbox. In the meantime,
            check out our <Link to="/journal">Journal</Link>
          </Text>
        )}
      </Box>
    </Box>
  )

  return <Box>{mailchimpMessage !== "" ? CompleteState : SignUpForm}</Box>
}

export default SignUp
